import React from 'react'
import HeaderFixed from '../component/HeaderFixed'
import Rules from '../component/Rules'
import Footer from '../component/Footer'

const RulesRoute = () => {
  return (
    <div>
      <HeaderFixed/>
      <Rules/>
      <Footer/>
    </div>
  )
}

export default RulesRoute

import React from 'react'
import HeaderFixed from '../component/HeaderFixed'
import ServerInfo1 from '../component/ServerInfo1'
import Footer from '../component/Footer'

const ServerInfoRoute = () => {
  return (
    <div>
      <HeaderFixed/>
      <ServerInfo1/>
      <Footer/>
    </div>
  )
}

export default ServerInfoRoute

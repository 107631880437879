import { AppBar, Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, styled } from '@mui/material'
import React, { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import DnsIcon from '@mui/icons-material/Dns';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import GavelIcon from '@mui/icons-material/Gavel';
import NavLogo from '../pics/FeinLogoNoBackground.jpg';
import {useNavigate} from 'react-router-dom'

const HeaderFixed = () => {
    const nav_titles=[{path:'/',display:'Home'},{path:'/server-info',display:'Server Info'},{path:'/rules',display:'Rules'},{path:'/shop',display:'Shop'},{path:'/support',display:'Support'}];
    const [mobileMenu,setMobileMenu]=useState({right:false});
    const navigate=useNavigate();
    const NavBarLinksBox=styled(Box)(({theme})=>({
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        gap:theme.spacing(3),
        [theme.breakpoints.down('md')]:{
            display:'none'
        }
    }))
    const NavBarLink=styled(Typography)(()=>({
        fontSize:'15px',
        color:'#fff',
        fontFamily:'Orbitron',
        fontWeight:'bold',
        cursor:'pointer',
        textShadow: '1.5px 1.5px #000000',
        '&:hover':{
            color:'#616161'
        }
    }))
    const NavBarLogo=styled("img")(()=>({
        cursor:'pointer',
        width:'50px',
        height:'50px',
    }))
    const NavBarName=styled(Typography)(({theme})=>({
        fontFamily:'Orbitron',
        textShadow: '2px 2px #c0ca33',
        fontSize:'40px',
        color:'#fff',
        fontWeight:'bold',
        cursor:'pointer',
        '&:hover':{
            color:'#616161'
        },
        [theme.breakpoints.down('md')]:{
            display:'none'
        }
    }))
    const CustomMenuIcon=styled(MenuIcon)(({theme})=>({
        cursor:'pointer',
        display:'none',
        color:'#fff',
        marginRight:theme.spacing(2),
        [theme.breakpoints.down('md')]:{
            display:'block'
        }
    }))
    const toggleDrawer=(anchor,open)=>(event)=>{
        if(event.type==='keydown'&& (event.key=== 'Tab' || event.key === 'Shift')){
            return;
        }
        setMobileMenu({...mobileMenu, [anchor]: open })
    };
    
    const list=(anchor)=>(
        <Box 
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250}}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {nav_titles.map((text, index) => (
                        <ListItem key={index} disablePadding>
                        <ListItemButton onClick={(()=>navigate(text.path))}>
                            <ListItemIcon>
                                {index===0 && <HomeIcon/>}
                                {index===1 && <DnsIcon/>}
                                {index===2 && <GavelIcon/>}
                                {index===3 && <ShoppingCartIcon/>}
                                {index===4 && <SupportAgentIcon/>}
                            </ListItemIcon>
                            <ListItemText primary={text.display}/>
                        </ListItemButton>
                    </ListItem>                    
                ))}
            </List>
        </Box>
    )
  return (
    <div style={{marginBottom:'70px'}}>
        <AppBar  style={{ backgroundColor: '#000000' , boxShadow: 'none', position:"fixed"}}>
      <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:'15px',maxWidth:'auto',marginLeft:'0px',marginBottom:'px'}}>
        <Box sx={{display:'flex',alignItems:'center',gap:'2.5rem'}}>
            <NavBarLogo src={NavLogo} onClick={(()=>navigate('/'))}/>
            <NavBarName  onClick={(()=>navigate('/'))}>FEIN ARK</NavBarName>            
        </Box>
        <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',gap:'2.5rem'}}>
        <NavBarLinksBox>
            {nav_titles.map((item,index)=>(
            <NavBarLink variant='body2' key={index} onClick={(()=>navigate(item.path))}>{item.display}</NavBarLink>
            ))}
        </NavBarLinksBox>
        <CustomMenuIcon onClick={toggleDrawer("right",true)}/>
        <Drawer anchor='right' open={mobileMenu["right"]} onClose={toggleDrawer("right",false)}>
            {list("right")}
        </Drawer>
        </Box>
      </Box>
      </AppBar>
    </div>
  )
}

export default HeaderFixed

import React from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'
import Welcome from '../component/Welcome'
import Info from '../component/Info'
import ServerInfo from '../component/ServerInfo'



const HomeRoute = () => {
  return (
    <div>
        <Header/>
        <Welcome/>
        <Info/>
        <ServerInfo/>
        <Footer/>
    </div>
  )
}

export default HomeRoute

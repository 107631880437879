import { Box, Container, Tooltip, Typography, styled } from '@mui/material'
import React from 'react'
import DiscordLogo from '../pics/DiscordIcon.png'
import YoutubeLogo from '../pics/YoutubeIcon.png'
import InstagramLogo from '../pics/InstagramLogo.png'
import {useNavigate} from 'react-router-dom'

const FooterLink=styled("span")(({theme})=>({
    display:'flex',
    fontSize:'18px',
    color:'#AAABB8',
    fontWeight:'300',
    cursor:'pointer',    
    '&:hover':{
        color:'#fff',
        fontWeight:'bold'
    },
    [theme.breakpoints.down("sm")]:{
        justifyContent:'center'
    }
}));
const CustomContainer=styled(Container)(({theme})=>({
        display: 'flex',
        justifyContent: 'space-between',
        gap: theme.spacing(5),
        [theme.breakpoints.down("sm")]:{
            flexDirection:'column',
            textAlign:'center'
        }
}));
const IconBox=styled(Box)(({theme})=>({
    display: 'flex',
    alignItems:'center',
    gap: '1rem',
    [theme.breakpoints.down("sm")]:{
        justifyContent:'center'
    }
}));

const Footer = () => {
    const navigate=useNavigate();
    const titles=[{path:'/server-info',display:'Server Info'},{path:'/rules',display:'Rules'},{path:'/shop',display:'Shop'},{path:'/support',display:'Support'}];
  return (
    <div>
      <Box sx={{py:10,backgroundColor:'#000000'}}>
        <CustomContainer>
            <Box>
                <Typography sx={{fontSize:'20px',color:'#fff',fontWeight:'700',mb:2}}>Other Links</Typography>
                {titles.map((item,index)=>(
                    <FooterLink key={index}  onClick={(()=>navigate(item.path))}>{item.display}</FooterLink>
                ))}
            </Box>
            <Box>
                <Typography sx={{fontSize:'20px',color:'#fff',fontWeight:'700',mb:2}}>Get in touch</Typography>
                <Typography sx={{fontSize:'16px',color:'#AAABB8',fontWeight:'500',mb:2}}>Keep in touch with our social medias.</Typography>
                <IconBox>
                    <Tooltip title='Discord'>
                        <a href="https://discord.gg/Gun428Busz"><img src={DiscordLogo} alt='DiscordIcon' style={{cursor:'pointer'}}/></a>
                    </Tooltip>
                    <Tooltip title='Youtube'>
                        <a href="https://www.youtube.com/channel/UCc7XNTG3mYfy3DZoF1RnGZw"><img src={YoutubeLogo} alt='YoutubeIcon' style={{cursor:'pointer'}}/></a>
                    </Tooltip>
                    <Tooltip title='Instagram'>
                        <a href="https://www.instagram.com/fein_ark/"><img src={InstagramLogo} alt='YoutubeIcon' style={{cursor:'pointer'}}/></a>
                    </Tooltip>
                </IconBox>
            </Box>
        </CustomContainer>
      </Box>
      <Box sx={{backgroundColor:'#fff',textAlign:'center',justifyContent:'center'}}>
        <Typography style={{fontWeight:'bold'}}>Copyright &copy; 2024 Fein Ark. All rights reserved.</Typography>
      </Box>
    </div>
  )
}

export default Footer

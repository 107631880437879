import { Alert, Box, Container, Grid, TextField, Typography, styled } from '@mui/material'
import React, { useRef, useState } from 'react'
import FeinLogoNoBackground from '../pics/FeinLogoNoBackground1.jpg'
import ArkBGImage from '../pics/ArkImage1.jpg'
import emailjs from '@emailjs/browser';



const Support = () => {
    const CustomBox=styled(Box)(({theme})=>({
        display:'flex',
        justifyContent:'space-between',
        textAlign: "center",
        [theme.breakpoints.down('md')]:{
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
        }
    }))
    const CustomImg=styled('img')(({theme})=>({
        height:'500px',
        width:'500px',
        marginLeft:'100px',
        [theme.breakpoints.down('md')]:{
            marginLeft:'0px',
            height:'250px',
            width:'250px'
        }
    }))
    const CustomTextField=styled(TextField)(({theme})=>({
        minWidth:'30vw',
        [theme.breakpoints.down('md')]:{
            
        }
    }))
    const CustomFormBox=styled(Box)(({theme})=>({
        padding:'80px',
        [theme.breakpoints.down('md')]:{
            padding:'0px',
            paddingBottom:'20px'
        }
    }))
    const ImgBg=styled('img')(({theme})=>({
        opacity:'0.5',
        position:'absolute',
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
        height:'100%',
        width:'100%',
        [theme.breakpoints.down('md')]:{
          width:'auto'
      }
    }))
    const CustomButton=styled('input')(({theme})=>({
        color:'#fff',
        backgroundColor:'#000000',
        fontWeight:'bold',
        paddingLeft:'20px',
        paddingRight:'20px',
        borderRadius:'5px',
        '&:hover':{
            color:'#616161'
        }
    }))
    const Heading=styled(Typography)(({theme})=>({  
        fontWeight:'bold',
        textAlign:'center',
        fontFamily:'Chakra Petch',
        fontSize:'64px',
        [theme.breakpoints.down('md')]:{
          fontSize:'40px',
          padding:'25px'
      }
    }))

    const [data, setData] = useState(null); 
    const form = useRef();

    const sendEmail = (e) => {
       
       e.preventDefault();

    emailjs.sendForm('service_7oi9hjh', 'template_t5rfe9i', form.current, {publicKey: 'Ggxe3Y1BksZ1_DJWe'})
      .then(
        () => {
          console.log('SUCCESS!');
          e.target.reset();
        //   alert('Message Send.');
        setData(<Alert severity="success">Message send successfully.</Alert>)
        setTimeout(() => {
            setData();
        },3000);        
        },
        (error) => {
          console.log('FAILED...', error.text);
        //   alert('Error sending message.')
        setData(<Alert severity="error">Error sending message.</Alert>)
        setTimeout(() => {
            setData();
        },3000);
        },
    );
    };

  return (
    <div>
      <Box style={{overflow:'hidden',position:'relative'}}>
      <ImgBg src={ArkBGImage} alt=""/>
      <Box style={{margin:'30px',position: 'relative'}}>
        <Container>
            <Heading>SUPPORT</Heading>
            <Typography style={{textAlign:'center',fontWeight:''}}>
                We recommend you to reach us via <a href="https://discord.gg/dvMz7njDn6">Discord</a> using a support ticket for a faster response. <br />
                However, you can also use the form below to directly email us. <br />
                Please fill the asked fields below  prior to submitting. <br />
                Any kind of reply will be send through email.
            </Typography>
        
        </Container>
      
      <CustomBox>
        <Box>
            <CustomImg src={FeinLogoNoBackground} alt="FeinLogo" />
        </Box>
        <CustomFormBox>
        <form ref={form} onSubmit={sendEmail}>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
                <CustomTextField variant='filled' label="Email" name='user_email' autoComplete='off' required/>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <CustomTextField variant='filled' label="Tribe Name/Player Name" name='user_igname' autoComplete='off' required/>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <CustomTextField variant='filled' label="Steam ID" name='user_steamid' autoComplete='off' required/>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <CustomTextField variant='filled' rows={4} multiline label="How can we help you" name='message' autoComplete='off' required/>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <CustomButton type="submit" value="Submit" />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
            <Box>
                <span>{data}</span>
            </Box>
            </Grid>    
        </Grid>        
        </form>
        </CustomFormBox>
      </CustomBox>
      </Box>
      </Box>
    </div>
  )
}

export default Support

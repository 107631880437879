import React from 'react'
import FeinLogoVideo from '../pics/feinlogovideo.mp4'


const Welcome = () => {

  return (
    <div>
      <video width="100%" height="auto" autoplay="autoplay" loop="loop" muted defaultMuted playsinline oncontextmenu="return false;"  preload="auto"  id="myVideo">
        <source src={FeinLogoVideo} type="video/mp4"/>
      </video>
    </div>
  )
}

export default Welcome
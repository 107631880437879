import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box, Container } from '@mui/material';
import ArkBGImage from '../pics/FeinImage2.jpg'


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem',color:'#c0ca33' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const CustomBox=styled(Box)(({theme})=>({
    backgroundColor:'#000000',
    paddingTop:'20px',
    paddingLeft:'200px',
    paddingRight:'200px',
    paddingBottom:'20px',
    [theme.breakpoints.down('md')]:{
      paddingLeft:'10px',
      paddingRight:'10px'
  }
  }))
  const HeadingTypo=styled(Typography)(()=>({
    fontSize:'20px',
    fontWeight:'bold',
    color:'#c0ca33'
  }))
  const ParaTypo=styled(Typography)(()=>({
    fontSize:'16px',
    fontWeight:'bold',
    marginLeft:'45px',
    color:''

  }))
  const CustomLi=styled('li')(()=>({
    listStyleType:'none',
    marginBottom:'20px'
  }))
  const ImgBg=styled('img')(({theme})=>({
    opacity:'0.8',
    position:'absolute',
    width:'100%',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    [theme.breakpoints.down('md')]:{
      height:'auto',
      width:'auto',
  }
}))
  const Heading=styled(Typography)(({theme})=>({  
    fontWeight:'bold',
    textAlign:'center',
    fontFamily:'Poetsen One',
    fontSize:'64px',
    [theme.breakpoints.down('md')]:{
      fontSize:'40px',
      padding:'25px'
  }
}))

  return (
    <div>
      <Box style={{overflow:'hidden',position:'relative'}}>
      <ImgBg src={ArkBGImage} alt=""/>
      <Box style={{margin:'30px',position: 'relative'}}>
        <Container>
          <Heading>RULES</Heading>
          <Typography style={{textAlign:'center',fontWeight:'bold'}}>        
          - Players not following rules may be banned without warning. <br />
          - Use the rule type and number when reporting. (ex. 1.7, 3.8) <br />
          - Report using a discord ticket.
          </Typography>
        </Container>
      </Box>
      </Box>
      <CustomBox>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{backgroundColor:'#000000'}}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <HeadingTypo>1. GENERAL RULES</HeadingTypo>
        </AccordionSummary>
        <AccordionDetails sx={{backgroundColor:'#000000'}}>
          <ParaTypo sx={{color:'#fff'}}>
          1.1 <CustomLi>Recommended language for communication is English.</CustomLi>
          1.2 <CustomLi>No hacks, exploits, duping, meshing or lag attempts</CustomLi>
          1.3 <CustomLi>Insiding is strictly forbidden - We can only change the owner of the tribe only if the 3 others members want it</CustomLi>
          1.4 <CustomLi>Cheating, aimbotting, duping or undermeshing (including shooting through mesh) is strictly prohibited and punished with a ban.</CustomLi>
          1.5 <CustomLi>Breaking WC's Code of conduct in any way is forbidden.</CustomLi>
          1.6 <CustomLi>Admins are not obliged to give back any property or dinos lost due to any in-game situation, bugs, outages and roll backs.</CustomLi>
          1.7 <CustomLi>Stacking of Dinos with elevator is strictly forbidden.</CustomLi>
          1.8 <CustomLi>Scam on trades & bosses is bannable plz record your trades</CustomLi>
          1.9 <CustomLi>Popcorn is now forbidden you can be banned for that - You need solid evidences</CustomLi>
          1.10 <CustomLi>Account sharing is bannable - A plugin will ban you if you use 2 different PC on the same account</CustomLi>
          </ParaTypo>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{backgroundColor:'#000000'}}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <HeadingTypo>2. PVP RULES</HeadingTypo>
        </AccordionSummary>
        <AccordionDetails sx={{backgroundColor:'#000000'}}> 
          <ParaTypo sx={{color:'#fff'}}>
          2.1 <CustomLi>Teaming with another tribe is not allowed : Join raid, defending an other tribe from an attack, coordinated world attack. ONLY Trading and doing bossfight is fine.</CustomLi>
          2.2 <CustomLi>Counter-fobbing is NOT allowed.</CustomLi>
          2.3 <CustomLi>You can only keep a player imprisoned for 2 hour.</CustomLi>
          2.4 <CustomLi>Meshbiting with Mek, Titan, Velo, Giga, Carcha... is forbidden even unsaddle.</CustomLi>
          2.5 <CustomLi>Whistle all aggressive at the end of a raid with more than 200 dinos, Uncryo all dinos, launch every eggs, or use dinos just to cause server lags and FPS drop will result to a ban</CustomLi>
          2.6 <CustomLi>Give every loots to an other tribe during a raid is teaming and can result to a ban for the 2 tribes.</CustomLi>
          2.7 <CustomLi>Using the range of the forcefield to block a player or a dino multiple times is fobidden (on official too)</CustomLi>
          2.8 <CustomLi>You cannot block the lava golem for more than 30min without doing anything</CustomLi>
          2.9 <CustomLi>During a raid, all members need to be the same / You can't change tribe members</CustomLi>
          2.10 <CustomLi>The use of any 3rd party software is not allowed! (crosshair and ini is ok)</CustomLi>
          2.11 <CustomLi>You are not allowed to abuse any type of glitch or bug!(includes but not limited to: placing structures or dinos in a way they cannot be damaged)</CustomLi>
          2.12 <CustomLi>No intentionally lagging the server!(This includes snapping too many structures together to cause lag while defending)</CustomLi>
          2.13 <CustomLi>You can have up to 5 tames before it is mass soak.</CustomLi>
          2.14 <CustomLi>If a tribe is being attacked at one location, a second tribe is not allowed to attack that same tribe in a different location.</CustomLi>
          </ParaTypo>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{backgroundColor:'#000000'}}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <HeadingTypo>3. BUILDING RULES</HeadingTypo>
        </AccordionSummary>
        <AccordionDetails sx={{backgroundColor:'#000000'}}>
          <ParaTypo sx={{color:'#fff'}}>
          3.1 <CustomLi>Do not build invisible or invincible structures or structures +80% in the mesh (must be visible AND breakable) admins will remove it without permission.Do not build in Player spawn, Wyvern Scars, Rock Drake nests, Crystal Bee cave & underwater loot tunnel, Gen1 Volcano entrance & inside, Gen1 Missons Terminals, Gen 2 water biome entrance, Ragnarok ice queen, golem, Aberration surface entrances, Valguero aberration biome entrances.</CustomLi>
          3.2 <CustomLi>Do not build in knock-out, zipline, grappling hook, kaprosuchus/pelagronis, mesh or chair spots (even spot where you need to jump 4-5times to enter in the rathole)</CustomLi>
          3.3 <CustomLi>Do not build in a mesh spot. If your spot have an access to the mesh, it's forbidden</CustomLi>
          3.4 <CustomLi>Open turrets are allowed on active PVP/farming only - Do not let more than 2 fully battery in it if you leave or you'll be warned</CustomLi>
          3.5 <CustomLi>No tunneling!(Including campfire tunneling)(Platform saddles do not apply) There must be at least 2 sides of the dino showing on platform saddles!. You are not allowed to use XL walls on platform saddles!</CustomLi>
          3.6 <CustomLi>Floating structures is forbidden (except turrets in the middle of hatchframes) - electric cables floating structures are forbidden</CustomLi>
          3.7 <CustomLi>Abusing stacking structures is prohibited (more than 2) - No pillar on vault, no staking vaults, ...</CustomLi>
          3.8 <CustomLi>You can't use this type of structures in your spam or cave entrance ( More than 2 vaults, Cryofridges, Transmitters, Pillar + Vaults on it..)</CustomLi>
          3.9 <CustomLi>Hard head glitching.</CustomLi>
          3.10 <CustomLi>ORP on FOB are forbidden</CustomLi>
          3.11 <CustomLi>Snap structures to the world border is forbidden</CustomLi>
          3.12 <CustomLi>You can't have more than 6 dinos in your cave entrance/wall (include velo, blood, giga, kentro, tuso...)</CustomLi>
          3.13 <CustomLi>Pick up your fob when you are done raiding! (If you don't plan to come back for 6 hours or more pick it up or admins will do it for you!)</CustomLi>
          3.14 <CustomLi>You are limited to 2 base locations cluster wide, farming outposts not included (only 2 alpha location per tribe)</CustomLi>
          3.15 <CustomLi>Any multiple entrance base spots such as Center Underworld, Valg Underworld and whatever else 1 entrance Must be open</CustomLi>
          </ParaTypo>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} sx={{backgroundColor:'#000000'}}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <HeadingTypo>4. DISCORD RULES</HeadingTypo>
        </AccordionSummary>
        <AccordionDetails sx={{backgroundColor:'#000000'}}>
          <ParaTypo sx={{color:'#fff'}}>
          4.1 <CustomLi>All channels must be used appropriately and solely for their intended use.</CustomLi>
          4.2 <CustomLi>Advertisement or promotional communication of other communities or clusters is completely forbidden.</CustomLi>
          4.3 <CustomLi>Pornographic, gore and explicit content or media is completely forbidden.</CustomLi>
          4.4 <CustomLi>Revealing people personal information is completely forbidden.</CustomLi>
          4.5 <CustomLi>Sharing of cheat software or in-game exploits is completely forbidden.</CustomLi>
          4.6 <CustomLi>Hard insults, racket, racism... in discord will be severely punished. There is a salt channel for trashtalk but this rule sill apply on this channel.</CustomLi>
          4.7 <CustomLi>Admins always have the final word. Rudeness, insults, abuse of personal messages, @ping or complaints will not be tolerated and can be punished with anything up to a ban.</CustomLi>
          </ParaTypo>
        </AccordionDetails>
      </Accordion>
      </CustomBox>
    </div>
  );
}

import './App.css';
import MainRoute from './Routes/MainRoute';

function App() {
  return (
    <>
    <MainRoute/>
    </>
  );
}

export default App;

import React from 'react'
import HeaderFixed from '../component/HeaderFixed'
import Shop from '../component/Shop'
import Footer from '../component/Footer'

const ShopRoute = () => {
  return (
    <div>
      <HeaderFixed/>
      <Shop/>
      <Footer/>
    </div>
  )
}

export default ShopRoute

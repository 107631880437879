import { Box, Tooltip, Typography, styled } from '@mui/material'
import React from 'react'
import StryderImage from '../pics/ARKLogo.png'
import DiscordLogo from '../pics/DiscordLogoWhite.png'

const Info = () => {
    const CustomBox=styled(Box)(({theme})=>({
        display: 'flex',
        backgroundColor:'#c0ca33',
        justifyContent:'space-evenly',
        gap: theme.spacing(5),
        paddingTop:'10px',
        [theme.breakpoints.down('md')]:{
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center"
        }
    }))
    const Customli=styled('li')(({theme})=>({
        fontSize: '20px',
        color: '#000000',
        fontWeight: '400',
        fontFamily:'Bruno Ace SC',
        paddingBottom:'10px',
        [theme.breakpoints.down('md')]:{
            textAlign:'left',
            fontSize: '20px',
            fontWeight: 'bold'
        }
    }))
    const CustomDC=styled(Box)(({theme})=>({
        justifyContent:'center',
        textAlign: "center",
        backgroundColor:'#000000',
        padding:'20px',
        marginTop:'-10px',        
    }))
    const CustomImage=styled('img')(({theme})=>({
        paddingTop:'20px',
        width:'600px',
        height:'400px',
        [theme.breakpoints.down('md')]:{
            width:'300px',
            height:'200px'
        }
    }))
    const CustomInfo=styled(Box)(({theme})=>({
        padding:'60px',
        [theme.breakpoints.down('md')]:{
            padding:'0px',
            paddingBottom:'20px'
        }
    }))
    const CustomTypo=styled(Typography)(({theme})=>({
        color:'#fff',
        fontFamily:'Orbitron',
        fontWeight:'bold',
        fontSize:'30px',
        [theme.breakpoints.down('md')]:{
            fontSize:'20px'
        }
    }))
    const CustomDCImage=styled('img')(({theme})=>({
        maxWidth:'25%',
        cursor:'pointer',
        [theme.breakpoints.down('md')]:{
            maxWidth:'50%'
        }
    }))
  return (
    <div>
        <CustomDC>
            <Box>
            <CustomTypo>JOIN</CustomTypo>
                <a href="https://discord.gg/Gun428Busz">
                    <Tooltip title='Join Discord'>
                        <CustomDCImage src={DiscordLogo} alt='DiscordLogo'/>
                    </Tooltip>
                </a>
            </Box>
        </CustomDC>




        <CustomBox>
            <Box>
                <CustomImage src={StryderImage} alt="ArkLogo"/>
            </Box>
            <CustomInfo>
                <span>
                    <Customli>24/7 Active Admins</Customli>
                    <Customli>Custom Drop & Boss</Customli>
                    <Customli>Custom Lag Free Turrets</Customli>
                    <Customli>4 Week Wipes</Customli>
                    <Customli>Ingame Shop Kit/Starter</Customli>
                    <Customli>Tribe Tracking & Pings!</Customli>
                    <Customli>Custom Plugins</Customli>
                    <Customli>Events and Giveaways</Customli>
                </span>
            </CustomInfo>
        </CustomBox>

    </div>
  )
}

export default Info

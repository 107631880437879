import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, Grid, Typography, styled } from '@mui/material'
import React from 'react'
import VipAscendant from '../pics/VipImages/AscVIP.png'
import VipMastercraft from '../pics/VipImages/MasterVIP.png'
import VipJourneyman from '../pics/VipImages/JourVIP.png'
import VipApprentice from '../pics/VipImages/AppVIP.png'
import VipRamshackle from '../pics/VipImages/RamVIP.png'
import VipBackground from '../pics/VipImages/VipBackground.png'
import PointsImage from '../pics/PointsImage.png'
import BpBoxImage from '../pics/BpBox.png'
import DediImage from '../pics/DediImage.png'

const Shop = () => {
const ImgBg=styled('img')(({theme})=>({
  opacity:'0.6',
  position:'absolute',
  backgroundRepeat:'no-repeat',
  backgroundSize:'cover',
  height:'100%',
  width:'100%',
  [theme.breakpoints.down('md')]:{
      width:'auto'
  }
}))
const CustomContainer=styled(Container)(({theme})=>({
  display: 'flex',
  justifyContent: 'space-around',
  gap: theme.spacing(5),
  paddingTop:'40px',
  [theme.breakpoints.down("sm")]:{
      flexDirection:'column',
      textAlign:'center',
      paddingTop:'20px'
  }
}));
const CustomTypo=styled(Typography)(({theme})=>({
  fontWeight:'bold',
        textAlign:'center',
        paddingTop:'40px',
        paddingBottom:'20px',
        fontFamily:'Orbitron',
        fontSize:'40px',
        [theme.breakpoints.down('md')]:{
            fontSize:'40px',
            padding:'25px'
        }
}))
const CustomGrid=styled(Grid)(({theme})=>({
  textAlign:'center',
  display:'flex',
  justifyContent:'center',
  paddingLeft:'160px',
  paddingBottom:'20px',
  [theme.breakpoints.down('md')]:{
    paddingLeft:'20px'
  }
}))
const SBox=styled(Container)(({theme})=>({
  display:'flex',
  justifyContent:'space-between',
  [theme.breakpoints.down('md')]:{
      flexDirection: "column",
      paddingLeft:'40px',
      paddingBottom:'20px'
  }
}))

  return (
    <div>
      <Box style={{overflow:'hidden',position:'relative',backgroundColor:'rgba(192, 4, 4, 0.5)'}}>
      <ImgBg src={VipBackground} alt=""/>
      <Box style={{position: 'relative',paddingBottom:'20px'}}>
        <CustomTypo>VIP Packages</CustomTypo>
        <CustomContainer>
          <Box>
          <div className='maincontainer'>
              <div className='thecard'>
                <div className='thefront'>
                  <Card sx={{ minWidth: 345,textAlign:'center',borderRadius:'30px'}}>
                    <CardActionArea>
                        <CardMedia
                        component="img"
                        height="400"
                        image={VipAscendant}
                        alt="Ascendant"
                        />
                    </CardActionArea>
                  </Card>
                </div>
                <div className='theback'>
                  <Card sx={{ minWidth: 345,minHeight:400,backgroundColor:'#000000',borderRadius:'30px'}}>
                    <CardActionArea>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div" textAlign={'center'} color={'#84ffff'}>
                              ASCENDANT PACK
                            </Typography>
                            <Typography gutterBottom variant="h5" component="div" textAlign={'center'} color={'#fff'}>
                              30$
                            </Typography>
                            <Typography variant="body2" color="#fff" textAlign={'center'}>
                                Pack contains <br />
                            </Typography>
                            <Typography variant='' color={'#fff'}>
                            <span>
                              <ul>
                                <li>60 Points Instead of 10 Per Minute</li>
                                <li>80,000 Points</li>
                                <li>Exclusive Ingame and Discord Roles</li>
                                <li>Rebuild Kits</li>
                                <li>Exclusive Access to #VIP Discord Channel</li>
                                <li>3 BluePrint Kit (Exclusive-Tier BluePrints)</li>
                              </ul>
                            </span>  
                            </Typography>                          
                        </CardContent>
                    </CardActionArea>
                    <CardActions style={{justifyContent: 'center'}}>
                        <Button size="small" color="primary" variant='contained' href='https://fein-ark.tebex.io/package/6269961' sx={{bottom: 0,position: "absolute",marginBottom:'20px',backgroundColor: "#000000"}}>Buy Now</Button>
                    </CardActions>
                  </Card>
                </div>
              </div>        
          </div>
          </Box>
          <Box>
          <div className='maincontainer'>
              <div className='thecard'>
                <div className='thefront'>
                  <Card sx={{ minWidth: 345,textAlign:'center',borderRadius:'30px'}}>
                    <CardActionArea>
                        <CardMedia
                        component="img"
                        height="400"
                        image={VipMastercraft}
                        alt="MasterCraft"
                        />
                    </CardActionArea>
                  </Card>
                </div>
                <div className='theback'>
                  <Card sx={{ minWidth: 345,minHeight:400,backgroundColor:'#000000',borderRadius:'30px'}}>
                    <CardActionArea>
                    <CardContent>
                            <Typography gutterBottom variant="h5" component="div" textAlign={'center'} color={'#ffee58'}>
                              MASTERCRAFT PACK
                            </Typography>
                            <Typography gutterBottom variant="h5" component="div" textAlign={'center'} color={'#fff'}>
                              25$
                            </Typography>
                            <Typography variant="body2" color="#fff" textAlign={'center'}>
                                Pack contains <br />
                            </Typography>
                            <Typography variant='' color={'#fff'}>
                            <span>
                              <ul>
                                <li>50 Points Instead of 10 Per Minute</li>
                                <li>70,000 Points</li>
                                <li>Exclusive Ingame and Discord Roles</li>
                                <li>Rebuild Kits</li>
                                <li>Exclusive Access to #VIP Discord Channel</li>
                                <li>2 BluePrint Kit (Exclusive-Tier BluePrints)</li>
                              </ul>
                            </span>  
                            </Typography>                          
                    </CardContent>
                    </CardActionArea>
                    <CardActions style={{justifyContent: 'center'}}>
                        <Button size="small" color="primary" variant='contained' href='https://fein-ark.tebex.io/package/6269959' sx={{bottom: 0,position: "absolute",marginBottom:'20px',backgroundColor: "#000000"}}>Buy Now</Button>
                    </CardActions>
                  </Card>
                </div>
              </div>        
            </div>
          </Box>
          <Box>
          <div className='maincontainer'>
              <div className='thecard'>
                <div className='thefront'>
                  <Card sx={{ minWidth: 345,textAlign:'center',borderRadius:'30px'}}>
                    <CardActionArea>
                        <CardMedia
                        component="img"
                        height="400"
                        image={VipJourneyman}
                        alt="Journeyman"
                        />
                    </CardActionArea>
                  </Card>
                </div>
                <div className='theback'>
                  <Card sx={{ minWidth: 345,minHeight:400,backgroundColor:'#000000',borderRadius:'30px'}}>
                    <CardActionArea>
                    <CardContent>
                            <Typography gutterBottom variant="h5" component="div" textAlign={'center'} color={'#7e57c2'}>
                              JOURNEYMAN PACK
                            </Typography>
                            <Typography gutterBottom variant="h5" component="div" textAlign={'center'} color={'#fff'}>
                              20$
                            </Typography>
                            <Typography variant="body2" color="#fff" textAlign={'center'}>
                                Pack contains <br />
                            </Typography>
                            <Typography variant='' color={'#fff'}>
                            <span>
                              <ul>
                                <li>40 Points Instead of 10 Per Minute</li>
                                <li>60,000 Points</li>
                                <li>Exclusive Ingame and Discord Roles</li>
                                <li>Rebuild Kits</li>
                                <li>Exclusive Access to #VIP Discord Channel</li>
                                <li>1 BluePrint Kit (Exclusive-Tier BluePrints)</li>
                              </ul>
                            </span>  
                            </Typography>                          
                        </CardContent>
                    </CardActionArea>
                    <CardActions style={{justifyContent: 'center'}}>
                        <Button size="small" color="primary" variant='contained' href='https://fein-ark.tebex.io/package/6269957' sx={{bottom: 0,position: "absolute",marginBottom:'20px',backgroundColor: "#000000"}}>Buy Now</Button>
                    </CardActions>
                  </Card>
                </div>
              </div>        
            </div>
          </Box>
        </CustomContainer>
        <CustomContainer>
          <Box>
          <div className='maincontainer'>
              <div className='thecard'>
                <div className='thefront'>
                  <Card sx={{ minWidth: 345,textAlign:'center',borderRadius:'30px'}}>
                    <CardActionArea>
                        <CardMedia
                        component="img"
                        height="400"
                        image={VipApprentice}
                        alt="Apprentice"
                        />
                    </CardActionArea>
                  </Card>
                </div>
                <div className='theback'>
                  <Card sx={{ minWidth: 345,minHeight:400,backgroundColor:'#000000',borderRadius:'30px'}}>
                    <CardActionArea>
                    <CardContent>
                            <Typography gutterBottom variant="h5" component="div" textAlign={'center'} color={'#303f9f'}>
                              APPRENTICE PACK
                            </Typography>
                            <Typography gutterBottom variant="h5" component="div" textAlign={'center'} color={'#fff'}>
                              15$
                            </Typography>
                            <Typography variant="body2" color="#fff" textAlign={'center'}>
                                Pack contains <br />
                            </Typography>
                            <Typography variant='' color={'#fff'}>
                            <span>
                              <ul>
                                <li>30 Points Instead of 10 Per Minute</li>
                                <li>30,000 Points</li>
                                <li>Exclusive Ingame and Discord Roles</li>
                                <li>Exclusive Access to #VIP Discord Channel</li>
                              </ul>
                            </span>  
                            </Typography>                          
                        </CardContent>
                    </CardActionArea>
                    <CardActions style={{justifyContent: 'center'}}>
                        <Button size="small" color="primary" variant='contained' href='https://fein-ark.tebex.io/package/6269953' sx={{bottom: 0,position: "absolute",marginBottom:'20px',backgroundColor: "#000000"}}>Buy Now</Button>
                    </CardActions>
                  </Card>
                </div>
              </div>        
            </div>
          </Box>
          <Box>
          <div className='maincontainer'>
              <div className='thecard'>
                <div className='thefront'>
                  <Card sx={{ minWidth: 345,textAlign:'center',borderRadius:'30px'}}>
                    <CardActionArea>
                        <CardMedia
                        component="img"
                        height="400"
                        image={VipRamshackle}
                        alt="Ramshackle"
                        />
                    </CardActionArea>
                  </Card>
                </div>
                <div className='theback'>
                  <Card sx={{ minWidth: 345,minHeight:400,backgroundColor:'#000000',borderRadius:'30px'}}>
                    <CardActionArea>
                    <CardContent>
                            <Typography gutterBottom variant="h5" component="div" textAlign={'center'} color={'#66bb6a'}>
                              RAMSHACKLE PACK
                            </Typography>
                            <Typography gutterBottom variant="h5" component="div" textAlign={'center'} color={'#fff'}>
                              10$
                            </Typography>
                            <Typography variant="body2" color="#fff" textAlign={'center'}>
                                Pack contains <br />
                            </Typography>
                            <Typography variant='' color={'#fff'}>
                            <span>
                              <ul>
                                <li>20 Points Instead of 10 Per Minute</li>
                                <li>20,000 Points</li>
                                <li>Exclusive Ingame and Discord Roles</li>
                                <li>Exclusive Access to #VIP Discord Channel</li>
                              </ul>
                            </span>  
                            </Typography>                          
                        </CardContent>
                    </CardActionArea>
                    <CardActions style={{justifyContent: 'center'}}>
                        <Button size="small" color="primary" variant='contained' href='https://fein-ark.tebex.io/package/6267028' sx={{bottom: 0,position: "absolute",marginBottom:'20px',backgroundColor: "#000000"}}>Buy Now</Button>
                    </CardActions>
                  </Card>
                </div>
              </div>        
            </div>
          </Box>
        </CustomContainer>
      </Box>
      </Box> 

      <Box sx={{backgroundColor:'#ffe082'}}>    
        <SBox>
          <Box sx={{paddingBottom:'20px'}}>
            <CustomTypo>BLUEPRINT BOX</CustomTypo>
            <Box sx={{textAlign:'center',display:'flex',justifyContent:'center'}}>
                <Card sx={{ minWidth: 300,textAlign:'center' }}>
                    <CardActionArea>
                        <CardMedia
                        component="img"
                        height="275"
                        image={BpBoxImage}
                        alt="fein"
                        />
                    </CardActionArea>            
                    <CardActions sx={{justifyContent: 'center'}}>
                        <Button size="small" color="primary" variant='contained' href='https://fein-ark.tebex.io/category/blueprints-box' sx={{backgroundColor: "#000000"}}>Buy</Button>
                    </CardActions>                
                </Card>
                </Box>
          </Box>
          <Box sx={{paddingBottom:'20px'}}>
            <CustomTypo>DEDI OF RESOURCE</CustomTypo>
            <Box sx={{textAlign:'center',display:'flex',justifyContent:'center'}}>
                <Card sx={{ minWidth: 300,textAlign:'center'}}>
                    <CardActionArea sx={{backgroundColor:'#9e9e9e'}}>
                        <CardMedia
                        component="img"
                        height="275"
                        image={DediImage}
                        alt="fein"
                        />
                    </CardActionArea>            
                    <CardActions sx={{justifyContent: 'center'}}>
                        <Button size="small" color="primary" variant='contained' href='https://fein-ark.tebex.io/category/dedi-of-resources' sx={{backgroundColor: "#000000"}}>Buy</Button>
                    </CardActions>                
                </Card>
                </Box>
          </Box>
        </SBox>
      </Box> 

      <Box sx={{backgroundColor:'#ffe082'}}>
        <CustomTypo>SHOP POINTS</CustomTypo>
        <CustomGrid container spacing={2} sx={{display:'flex'}}>
          <Grid item xs={6} md={2} sm={2}>
          <Card sx={{ maxWidth: 150}}>
              <CardActionArea>
                <CardMedia
                component="img"
                height="150px"
                image={PointsImage}
                alt="PointsImage"
              />
              </CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">2500 Points</Typography>
                <Button size="small" color="primary" variant='contained' href='https://fein-ark.tebex.io/package/6268798' sx={{backgroundColor: "#000000"}}>Buy Now 2$</Button>
              </CardContent>              
          </Card>
          </Grid>
          <Grid item xs={6} md={2} sm={2}>
          <Card sx={{ maxWidth: 150}}>
              <CardActionArea>
                <CardMedia
                component="img"
                height="150px"
                image={PointsImage}
                alt="PointsImage"
              />
              </CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">5000 Points</Typography>
                <Button size="small" color="primary" variant='contained'href='https://fein-ark.tebex.io/package/6268805' sx={{backgroundColor: "#000000"}}>Buy Now 4$</Button>
              </CardContent>              
          </Card>
          </Grid>
          <Grid item xs={6} md={2} sm={2}>
          <Card sx={{ maxWidth: 150}}>
              <CardActionArea>
                <CardMedia
                component="img"
                height="150px"
                image={PointsImage}
                alt="PointsImage"
              />
              </CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">10000 Points</Typography>
                <Button size="small" color="primary" variant='contained' href='https://fein-ark.tebex.io/package/6268811' sx={{backgroundColor: "#000000"}}>Buy Now 5$</Button>
              </CardContent>             
          </Card>
          </Grid>
          <Grid item xs={6} md={2} sm={2}>
          <Card sx={{ maxWidth: 150}}>
              <CardActionArea>
                <CardMedia
                component="img"
                height="150px"
                image={PointsImage}
                alt="PointsImage"
              />
              </CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">20000 Points</Typography>
                <Button size="small" color="primary" variant='contained' href='https://fein-ark.tebex.io/package/6268816' sx={{backgroundColor: "#000000"}}>Buy Now 10$</Button>
              </CardContent>              
          </Card>
          </Grid>
          <Grid item xs={6} md={3} sm={3}>
          <Card sx={{ maxWidth: 150}}>
              <CardActionArea>
                <CardMedia
                component="img"
                height="150px"
                image={PointsImage}
                alt="PointsImage"
              />
              </CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">30000 Points</Typography>
                <Button size="small" color="primary" variant='contained' href='https://fein-ark.tebex.io/package/6268820' sx={{backgroundColor: "#000000"}}>Buy Now 15$</Button>
              </CardContent>              
          </Card>
          </Grid>
          <Grid item xs={6} md={3} sm={3}>
          <Card sx={{ maxWidth: 150}}>
              <CardActionArea>
                <CardMedia
                component="img"
                height="150px"
                image={PointsImage}
                alt="PointsImage"
              />
              </CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">40000 Points</Typography>
                <Button size="small" color="primary" variant='contained' href='https://fein-ark.tebex.io/package/6268842' sx={{backgroundColor: "#000000"}}>Buy Now 20$</Button>
              </CardContent>              
          </Card>
          </Grid>
          <Grid item xs={6} md={3} sm={3}>
          <Card sx={{ maxWidth: 150}}>
              <CardActionArea>
                <CardMedia
                component="img"
                height="150px"
                image={PointsImage}
                alt="PointsImage"
              />
              </CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">50000 Points</Typography>
                <Button size="small" color="primary" variant='contained' href='https://fein-ark.tebex.io/package/6268845' sx={{backgroundColor: "#000000"}}>Buy Now 25$</Button>
              </CardContent>             
          </Card>
          </Grid>
        </CustomGrid>
      </Box> 

      
      
    </div>
  )
}

export default Shop

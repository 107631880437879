import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, Grid, Typography, styled } from '@mui/material'
import React from 'react'
import ArkExtinctionImage from '../pics/ArkExtinction.jpg'
import ArkTheCenterImage from '../pics/ArkTheCenter.jpg'
import ArkRagnarokImage from '../pics/ArkRagnarok.jpg'
import ArkGenesis2Image from '../pics/ArkGenesis2.jpg'
import ArkBGImage from '../pics/FeinImage.jpg'
import FeinBanner from '../pics/FeinBanner.png'
import FeinLogo from '../pics/FeinLogo.png'


const ServerInfo1 = () => {
    const CustomGrid=styled(Grid)(({theme})=>({
        display:'flex',
        justifyContent:'center',
        textAlign:'center',
        marginLeft:'100px',
        [theme.breakpoints.down('md')]:{
            marginLeft:'0px',
            paddingRight:'30px'
        }
    }))
    const ImgBg=styled('img')(({theme})=>({
        opacity:'0.5',
        position:'absolute',
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
        height:'100%',
        width:'100%',
        [theme.breakpoints.down('md')]:{
            width:'auto'
        }
    }))
    const Banner=styled('img')(({theme})=>({
        opacity:'0.3',
        position:'absolute',
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
        height:'100%',
        width:'100%'
    }))
    const Heading=styled(Typography)(({theme})=>({  
        fontWeight:'bold',
        textAlign:'center',
        padding:'100px',
        fontFamily:'Poetsen One',
        fontSize:'64px',
        [theme.breakpoints.down('md')]:{
            fontSize:'40px',
            padding:'25px'
        }
    }))
    const CustomBox=styled(Box)(({theme})=>({
        minHeight:'40vh',
        backgroundColor:'#000000',        
        padding:'40px',
        [theme.breakpoints.down('md')]:{
            padding:'10px'
        }
    }))
    const SSTypo=styled(Typography)(({theme})=>({
        fontWeight:'bold',
        fontFamily:'Orbitron',
        color:'#c0ca33',
        textAlign:'center',
        fontSize:'30px',
        [theme.breakpoints.down('md')]:{
            fontSize:'20px',
            paddingTop:'20px'
        }
    }))
    const STypo=styled(Typography)(({theme})=>({
        fontWeight:'bold',
        fontFamily:'Orbitron',
        color:'#c0ca33',
        textAlign:'center',
        fontSize:'30px',
        [theme.breakpoints.down('md')]:{
            fontSize:'15px',
            paddingTop:'20px'
        }
    }))
    const SSBox=styled(Container)(({theme})=>({
        color:'#fff',
        display:'flex',
        justifyContent:'space-between',
        marginTop:'40px',
        [theme.breakpoints.down('md')]:{
            flexDirection: "column",
            paddingLeft:'40px',
            paddingBottom:'20px'
        }
    }))
    const Customli=styled('li')(({theme})=>({
        marginBottom:'20px',
        fontSize: '20px',
        fontFamily:'Chakra Petch',
        color: '#fff',
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]:{
            textAlign:'left',
            fontSize: '15px',
            fontWeight: 'bold',
            marginBottom:'0px'
        }
    }))
  return (
    <div style={{paddingTop:'1px'}}>
        <Box style={{overflow:'hidden',position:'relative'}}>
            <Banner src={FeinBanner} alt=""/>
            <Box style={{position: 'relative'}}>
            <Heading>SERVER INFO</Heading>
        </Box>
        </Box>

        <CustomBox>
            <SSTypo>SERVER SETTINGS</SSTypo>
            <SSBox>
                <Box>
                    <Customli>Harvest Amount : 20x</Customli>
                    <Customli>Taming Speed : 20x</Customli>
                    <Customli>XP Multiplier : 20x</Customli>
                    <Customli>Crafting speed : Infinite</Customli>
                    <Customli>Mating Speed: 100x</Customli>
                    <Customli>Egg Hatch Speed: 100x</Customli>
                    <Customli>Baby Mature Speed: 150x</Customli>
                </Box>
                <Box>
                    <Customli>Player HP : 1000 (cap)</Customli>
                    <Customli>Max Tribe Members : 4</Customli>
                    <Customli>Alliances: Not allowed</Customli>
                    <Customli>Tribeslot Cooldown : 2 hrs</Customli>
                    <Customli>ORP (Activates after 60 mins)</Customli>
                    <Customli>Auto Unlock Engrams (Including Tek)</Customli>
                    <Customli>Allow Flyers in Caves: Yes</Customli>
                </Box>                
            </SSBox>
        </CustomBox>

        <Box style={{overflow:'hidden',position:'relative'}}>
            <ImgBg src={ArkBGImage} alt=""/>
            <Box style={{position: 'relative',paddingBottom:'20px'}}>
            <Typography variant='h4' style={{fontWeight:'bold',fontFamily:'Orbitron',textAlign:'center',marginTop:'40px',color:'#000000',marginBottom:'40px'}}>MAPS</Typography>
        <Container>
            <CustomGrid container spacing={2}>
                <Grid item xs={12} md={6} sm={6}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardActionArea>
                            <CardMedia
                            component="img"
                            height="200"
                            image={ArkExtinctionImage}
                            alt="Valguero"
                            />
                        </CardActionArea>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">Extinction</Typography>
                            <Typography variant="body2" color="text.secondary" style={{fontWeight:'bold'}}>Server IP: feinarkext.ggwp.cc:23314</Typography>
                        </CardContent>
                        
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardActionArea>
                            <CardMedia
                            component="img"
                            height="200"
                            image={ArkTheCenterImage}
                            alt="The Center"
                            />
                        </CardActionArea>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">The Center</Typography>
                            <Typography variant="body2" color="text.secondary" style={{fontWeight:'bold'}}>Server IP: feinarkcen.ggwp.cc:23309</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardActionArea>
                            <CardMedia
                            component="img"
                            height="200"
                            image={ArkRagnarokImage}
                            alt="Ragnarok"
                            />
                        </CardActionArea>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">Ragnarok</Typography>
                            <Typography variant="body2" color="text.secondary" style={{fontWeight:'bold'}}>Server IP: feinarkrag.ggwp.cc:23679</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardActionArea>
                            <CardMedia
                            component="img"
                            height="200"
                            image={ArkGenesis2Image}
                            alt="Genesis 2"
                            />
                        </CardActionArea>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">Genesis 2</Typography>
                            <Typography variant="body2" color="text.secondary" style={{fontWeight:'bold'}}>Server IP: feinarkgen2.ggwp.cc:23402</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </CustomGrid>
        </Container>
            </Box>
        </Box>
        
        <CustomBox>
            <STypo>MODS</STypo>
            <Box sx={{textAlign:'center',display:'flex',justifyContent:'center',paddingTop:'40px'}}>
            <Card sx={{ minWidth: 300,textAlign:'center' }}>
                <CardActionArea>
                    <CardMedia
                    component="img"
                    height="200"
                    image={FeinLogo}
                    alt="fein"
                    />
                </CardActionArea>
                <CardContent sx={{margin:'0'}}>
                    <Typography variant="h5">Fein ARK PvP 20x</Typography>
                    <Typography color="text.secondary">Steam Workshop</Typography>
                </CardContent>                
                <CardActions sx={{justifyContent: 'center'}}>
                    <Button size="small" color="primary" variant='contained' href='https://steamcommunity.com/sharedfiles/filedetails/?id=3243765054' sx={{backgroundColor: "#000000"}}>View</Button>
                </CardActions>                
            </Card>
            </Box>
        </CustomBox>
    </div>
  )
}

export default ServerInfo1

import React from 'react'
import HeaderFixed from '../component/HeaderFixed'
import Support from '../component/Support'
import Footer from '../component/Footer'

const SupportRoute = () => {
  return (
    <div>
      <HeaderFixed/>
      <Support/>
      <Footer/>
    </div>
  )
}

export default SupportRoute

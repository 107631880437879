import React from 'react'
import {BrowserRouter as Router,Route,Routes} from 'react-router-dom'
import HomeRoute from './HomeRoute'
import ServerInfoRoute from './ServerInfoRoute'
import RulesRoute from './RulesRoute'
import ShopRoute from './ShopRoute'
import SupportRoute from './SupportRoute'

const MainRoute = () => {
  return (
    <div>
      <Router>
            <Routes>
                <Route path='/' element={<HomeRoute/>}></Route> 
                <Route path='/server-info' element={<ServerInfoRoute/>}></Route>
                <Route path='/rules' element={<RulesRoute/>}></Route>
                <Route path='/shop' element={<ShopRoute/>}></Route>
                <Route path='/support' element={<SupportRoute/>}></Route>               
            </Routes> 
        </Router>
    </div>
  )
}

export default MainRoute
